
    
   .button-projects{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
gap: 10px;

    
   
    
    
   }
      
     
      
      .button-projects .text-con-1{
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
       
       
  
      }

      .button-projects .text-con-1 .text-1{
        margin-left: 10px;
        margin-top: 25px;
        color: var(--3, #F1F1F1);
        font-family: Dela Gothic One;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120.037%; /* 2.62581rem */
        text-transform: uppercase;
    }

    .button-projects .text-con-2{
      display: flex;
     width: 100%;
     height: 100%;
    
    }
.button-projects .text-con-2 .text-2 {
  margin-left: 10px;
  color: var(--3, #F1F1F1);
  font-family: Montserrat;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 

}

.picture-containe-p {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;


}

/* Style the profile picture container */
.profile-picture-p {
display: flex;
justify-content: flex-end;
  flex-shrink: 0;
  width: 100%;
  height: 100%;

}

/* Style the profile picture image */
.slaq-1{
align-self: flex-end;
}



    
      
    
      
     @media (max-width: 768px) {
.button-projects{
  width: 90%;
  justify-content: center;
  align-self: center;
  padding-top: 30px;
}



.button-projects .text-con-1 .text-1{
  margin-left: 10px;
  color: var(--3, #F1F1F1);
  font-family: Dela Gothic One;
  font-size: 7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120.037%; /* 2.62581rem */
  text-transform: uppercase;
}
.button-projects .text-con-2 .text-2{
      font-size: 3.5vw;
    }

    .profile-picture-p{
        width: 10vw;
        height: 10vw;
    }

}