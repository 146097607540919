.button-services{
  display: flex;
  flex-direction: column;
  gap: 10px;
 }
    
   
    
    .button-services .services-text-con-1{
      display: flex;
      height: 100%;
      justify-content:flex-start;
      margin-left: 10px;
      margin-top: 10px;

     

    }

    .button-services .services-text-con-1 .services-text-1{
      margin-left: 10px;
      color: var(--2, #050505);
      font-family: Dela Gothic One;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120.037%; /* 2.62581rem */
      text-transform: uppercase;
   
  }

  .button-services .services-text-con-2{
    width: 100%;
height: 100%;
   
    
   
  }
.button-services .services-text-con-2 .services-text-2 {
  margin-left: 10px;
  color: var(--2, #050505);
  font-family: Montserrat;
  font-size: 0.8rem ;
  font-style: normal;
  font-weight: 500;
  line-height: 120.037%; /* 1.35044rem */

}


/* servicebutton.css */

/* Style the container for service buttons */
.services-svg-con {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;

}


/* Style the service button image */


.servive-svg-item img {
  width: 100%; 
  height: 100%; 
 
}

@media (max-width: 768px) {

  .button-services{
    width: 90%;
    align-self: center
   }

  .services-svg-con {
    width: 95%;
   
    padding-top: 30px;
  
  }

 
  .button-services .services-text-con-1 .services-text-1{
    font-size: 7vw;
  }
  .button-services .services-text-con-2 .services-text-2 {
  
    font-size: 3.5vw ;
  
  
  }


  .servive-svg-item {
    width: 10vw;
    height: 10vw;
  }
}
 