/* Login.scss */
.login{
  display: flex;
  justify-content: center;
}
.root {
  width: 100%;
  padding: 200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
 
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.field {
  margin-bottom: 20px;
}

.button-login {
  margin-top: 10px;
}

.registerText {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}