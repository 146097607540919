:root {
  font-size: 16px; /* Set your base font size in rem here */
}

.container-about-home {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px; /* 30px in rem */
  flex-shrink: 0;
  gap: 2.5rem; /* 40px in rem */
  background-color: #F1F1F1;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition effect */
}

.container-about-home p {
  display: flex;
  justify-content: center;
  color: var(--2, #050505);
  font-family: Montserrat;
  font-size: 1rem; /* 1.5vw in rem */
  font-style: normal;
  text-transform: uppercase;
  transition: color 0.3s ease; /* Smooth transition effect */
}

.container-about-home:hover {
  background-color: #D1D1D1; /* Change background color on hover */
}

.container-about-home:hover p {
  color: #38CDA1 /* Change text color on hover */
}




.union-svg-item {
  width: 100%;
  display: flex;
}

.union-svg-con {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%; /* 33% of the base font size */
}

.img-slaq {
  margin: -20px;
  width: 100%;
  height: 100%;
}

/* Style the service button image */

@media (max-width: 680px) /* 768px in rem */ {
  .home-container .item.item-1 .container-1 .button .about {
    justify-content: flex-start;
  }
  .container-about {
    width: 100%;
    height:100%; /* 100% of the base font size */
    border: 0.0625rem solid; /* 1px in rem */
  }
  .aboute-text {
    justify-content: flex-start;
    font-size:1rem; /* 0.9rem in rem */
  }
  .container-about-home p{
    font-size:12px;
    margin-left: 25px;
  }
  .union-svg-item {
    /* Adjust as needed */
  }
  .svg-icon {
    /* Adjust as needed */
  }
  .container-about-home{
    width: 100px;
   
  }
}
