.header {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
}

.header .navbar {
  width: 100%;
  height: 80px;
  background-color: #000405fa;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 9999;
}

.header .navbar .logo {
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
}

.logo-img {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 6;
}

.text-1 {
  align-self: center;
  color: var(--3, #f1f1f1);
  font-size: 2vw;
  font-family: Anton;
  font-style: normal;

  margin-left: 10px;
  text-transform: uppercase;
}

/* Header Styles */
.nav-button-header {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.nav-button-header.open svg {
  transform: rotate(90deg);
}

.nav-button-header svg {
  transition: transform 0.3s ease-in-out;
}

/* DRopdown */

.menu-dropdown {
  position: absolute;
  top: 65px; /* Adjust this value as needed to position the dropdown below the header */
  left: 0;
  width: 100%;
  height: calc(
    100vh - 80px
  ); /* Calculate the height to fit the remaining viewport height */
  background-color: #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-dropdown.menu-dropdown-open {
  display: flex;
  flex-direction: column;
}

.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  border-radius: 25px;
  margin: 0;
  width: 500px;
}

.nav-links a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  transition: background-color 0.3s ease;

  text-align: center; /* Centers the text within the link */
}

/* Nav button container */

.nav-button-header {
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-right: 25px;
}

/* Dropdown menu */
.menu-dropdown .nav-links li {
  display: inline-block;
  margin-right: 20px;
  border: 1px solid;
  border-color: #f1f1f1;
}

/* Default size for the links */
.menu-dropdown .nav-links li a {
  font-size: 16px;
  text-decoration: none;
  color: #ffffff;
  transition: font-size 0.2s ease;
}

/* Style for links when clicked */
.menu-dropdown .nav-links li a:active {
  font-size: 4rem; /* Increase the font size when clicked */
  transition: font-size 0.2s ease;
}

@media (max-width: 768px) {
  .header .navbar {
    width: 100%;
    max-height: 40px;
    border-radius: 0px;
  }

  .header .navbar .logo {
    order: -1;
  }

  .img-hed {
    width: 100%;
    height: 100%;
  }

  .menu-dropdown{
    top: 35px;
    height: 100vh;
  }
  .nav-links li {
    border-radius: 25px;
    margin: 10px;
    width: 300px;
  }
  /* Adjust other styles as needed for mobile screens */
}
