/* Main container for the projects section */
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.projects h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Container for the project lists */
.project-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin-bottom: 20px;
}

/* Container for the individual project cards */
.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

/* Flex item styling to ensure equal size */
.item-list .project-card {
  width: 300px; /* Fixed width */
  height: 400px; /* Fixed height */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

/* Navigation buttons container */
.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* Navigation buttons styling */
.nav-button {
  background-color: #38CDA1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* Hover effect for navigation buttons */
.nav-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Disabled state for navigation buttons */
.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .item-list .project-card {
    width: 100%; /* Ensure full width on smaller screens */
    height: auto; /* Allow height to adjust on smaller screens */
  }

  .nav-button {
    width: 100px;
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .projects {
    padding: 10px;
  }

  .item-list .project-card {
    width: 100%; /* Ensure full width on smaller screens */
    height: auto; /* Allow height to adjust on smaller screens */
  }

  .nav-button {
    width: 80px;
    padding: 6px 0;
    font-size: 0.9rem;
  }
}
