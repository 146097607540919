/* General About Section */
.about {
  


  color: white;
  transition: background-color 0.3s ease;
}



.about__container {
  padding: 50px 20px;
  background: linear-gradient(45deg, #2ea68f, #47c9b0);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

/* Profile Picture */
.about__picture-container {
  margin-top: 100px;
  flex: 1 1 300px;
  display: flex;
  justify-content: center;
}

.about__picture {
  width: 250px;
  height: auto;
  border-radius: 50%;
  border: 5px solid #fff;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about__picture-container a:hover .about__picture {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Text Section */
.about__text-container {
  flex: 2 1 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
}

.about__heading {
  font-size: 2.5rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.about__description {
  font-size: 1.2rem;
  color: #f0f0f0;
  line-height: 1.8;
}

.about__details p {
  font-size: 1.1rem;
  color: #fff;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Skills Section */
.about__skills {
  margin-top: 20px;
}

.about__skills-heading {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 10px;
}

.about__skills-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.about__skills-item {
  font-size: 1rem;
  color: #fff;
  background-color: #2b2b2b;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.about__skills-item:hover {
  background-color: #47c9b0;
}

/* Testimonials Section */
.about__testimonials {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #fff;
}

.about__testimonials h3 {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 15px;
}

.about__testimonial {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 20px;
  background-color: #333;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about__testimonial p {
  margin: 5px 0;
}

.about__testimonial p:last-child {
  font-weight: bold;
  color: #47c9b0;
}
