.root {
 
  max-width: 800px;
  min-height: 700px;
  margin: 20px auto;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 20px;
}




/* Media Query for Small Screens */
@media (max-width: 767px) {
  .post-pair {
    flex-direction: column;
  }
}

/* Wrapper Styles */
.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column; /* Adjust as needed */
  gap: 20px;
}

/* Post Content */
.content {
  margin-bottom: 20px;
}

.content p {
  line-height: 1.6;
  margin-bottom: 40px;
}

/* Tags Section */
.tags {
  max-width: 500px;
  margin-top: 20px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tags li {
  display: inline;
 
  margin-right: 10px;
  font-size: 0.9em;
}

.image {
  width: 100%; /* Set a fixed width for the image */
  height: 300px; /* Set a fixed height for the image */
  border-radius: 25px;
  margin-bottom: 20px;
  background-size: cover;
  max-width: 800px;
}
.indention {
  /* Add your styles for the .indention class here */
  padding: 25px; /* Adjust the padding as needed */
  background-color: #f0f0f0; /* Example background color */
  border-radius: 15px; /* Example border radius */
}
.title {
  /* Add your styles for the .title class here */
  font-size: 24px; /* Example font size */
  color: #333; /* Example text color */
  text-decoration: none; /* Remove underline */
}
 

.titleFull {
  /* Add your styles for the .titleFull class here */
  font-size: 32px; /* Example larger font size for full post */
  color: #555; /* Example different text color for full post */
  text-decoration: none; /* Remove underline */
}
.postLink{
  text-decoration: none; /* Remove underline */

}

/* Animation for Post Entry */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.root {
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 767px) {
  .root {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .image {
    width: 100%; /* Full width for images on mobile */
    height: auto; /* Auto height to maintain aspect ratio */
    margin-bottom: 10px;
  }

  .tags {
    margin-top: 10px;
    font-size: 0.8em; /* Smaller font size for tags */
  }
}
