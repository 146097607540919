/* src/styles/components/pages/Home.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


.home {
  display: flex;
  justify-content: center;


}

.home-container {
 width: 100%;
max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  padding: 20px;
  background-color: #F1F1F1;

  border-radius: 5px;
  padding-top: 80px;
  padding-left: 20px;
  gap: 20px;
}




.home-container .item.item-1 {
  display: flex;
  flex-flow: row ;


  gap: 20px;
}

 .container-1 {
  display: flex;
  flex-direction: column;
 width: 50%;
  gap: 20px;
  
}

 .container-1 .text {

width: 100%;
justify-content: flex-start;
align-items: center;


}

/* HomeText.css */

.welcome-header1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
  max-height: 50%;
}

.hello {
  color: var(--2, #050505);
  font-family: Dela Gothic One;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120.037%; /* 4.8015rem */
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.hello:hover {
  color: #38CDA1; /* Change text color on hover */
}

.im-harut {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.im, .harut {
  color: var(--2, #050505);
  font-family: Dela Gothic One;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  max-width: 80%;
  transition: color 0.3s ease;
}

.im {
  padding-right: 10px;
  font-size: 7rem;
  line-height: 105.037%; /* 6.69613rem */
}

.im:hover {
  color: #38CDA1; /* Change text color on hover */
}

.harut {
  color: var(--1, #38CDA1);
  font-size: 6.5rem;
  line-height: 105.037%; /* 6.69613rem */
}

.harut:hover {
  color: #050505; /* Change text color on hover */
}

.full, .web {
  color: var(--2, #050505);
  font-family: Dela Gothic One;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 3.9rem;
  line-height: 123.537%; /* 4.86425rem */
  transition: color 0.3s ease;
}

.full:hover, .web:hover {
  color: #38CDA1; /* Change text color on hover */
}


.welcome-header1 .offer {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: flex-start;
  padding: 0 0px;
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
}

.welcome-header1 .offer:hover {
  color: #38CDA1; /* Change text color on hover */
  background-color: #f0f0f0; /* Add background color on hover */
  transform: scale(1.05); /* Add scale effect on hover */
}

.welcome-header1 .offer strong {
  font-weight: bolder;
  transition: color 0.3s ease; /* Smooth transition effect */
}

.welcome-header1 .offer strong:hover {
  color: #ff5733; /* Change strong text color on hover */
}

.welcome-header1 .offer em {
  font-style: italic;
  transition: color 0.3s ease; /* Smooth transition effect */
}

.welcome-header1 .offer em:hover {
  color: #38CDA1; /* Change italic text color on hover */
}



@media (max-width: 1300px){
  .im {
    
    font-size: 4.375rem;
  }

  .harut {
    font-size: 4.375rem;
  }

  .full, .web{
    font-size: 2.5rem;
  }

}

/* cottact about */



/* Home.css */

 .container-1 .button-home {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  height: 20%;
  width: 100%;
  
}

 .container-1 .button-home .contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 37%;
  height: 100%;
  
}

 .container-1 .button-home .about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  width: 50%;
  height: 100%;
}









/* Home.css */

/* picture container */
.home-container .item.item-1 .picture-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 10px;
}

/* profile picture container */
.profile-picture {
  display: flex;
height: auto;
 
  padding-left: 20px;


  overflow: hidden;

}

/* profile picture */
.profile-picture img {
  max-width: 100%;
  height: 100%;
  border-radius: 25px;
  align-self: flex-end;
 
}






/* .item.item-2  */


.home-container .item.item-2 {
  display: flex;
  flex-flow: row;

  gap: 30px;
  max-height: 100% /* Add the max-height property and set it to 220px */

}



.home-container .item.item-2 .button-1 {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  flex-shrink: 0;
  border-radius: 25px;
  background-color: #050505;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.home-container .item.item-2 .button-1:hover {
  background-color: #38CDA1; /* Change background color on hover */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Change box shadow on hover */
  transform: translateY(-5px); /* Add a slight lift effect on hover */
}



.home-container .item.item-2 .button-2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  border: 5px solid #000; /* Example border color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  flex-shrink: 0;
  border-radius: 25px;
}

.home-container .item.item-2 .button-2:hover {
  border-color: #38CDA1; /* Change border color on hover */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Change box shadow on hover */
  transform: translateY(-5px); /* Add a slight lift effect on hover */
}



.home-container .item.item-2 .button-3 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: #38CDA1;
  gap: 20px;
  flex-shrink: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 25px;
}

.home-container .item.item-2 .button-3:hover {
  background-color: #2EA68F; /* Change background color on hover */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Change box shadow on hover */
  transform: translateY(-5px); /* Add a slight lift effect on hover */
}



.home-container .item.item-2 .social-link-container {
  flex: 1;
  display: flex;
  flex-direction: column;
 padding-right: 10px;
  gap: 20px;
  height: 100%;

}

.social-link-container .cv-item {
display: flex;

width: 100%;
min-width: 80px;
  height: 50%;
  border-radius: 30px;
  background-color: black;
flex-direction: row;
  align-items: center;
  justify-content: center;
}








/* Apply styles to the <svg> element within the <div> */

.social-link-container .mail {
  
  display: flex;
  width: 100%;
    height: 50%;
    border-radius: 30px;
}

.social-link-container .mail .mail-1 {
  height: 100%;
  width: 100%;
  min-width: 80px;
  text-decoration: none;
  /* Remove underline from links */
  background-color: black;
  /* Optional: Add a background color */
  border-radius: 30px;
  /* Optional: Make the links circular */
  color: #fff;
  /* Optional: Set text color to white */
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-link-container .git {
  align-items: flex-end;
  display: flex;
  width: 100%;
    height: 50%;
    border-radius: 30px;
}

.social-link-container .git .git-1 {

  height: 100%;
  width: 100%;
  text-decoration: none;
  background-color: black;
  border-radius: 30px;
  /* Optional: Make the links circular */
  color: #fff;
  /* Optional: Set text color to white */
  display: flex;
  align-items: center;
  justify-content: center;

}

.social-link-container .linkdin {
align-items: flex-end;
  display: flex;
  width: 100%;
    height: 50%;
    border-radius: 30px;
}

.social-link-container .linkdin .linkdin-1 {

  height: 100%;
  width: 100%;
  text-decoration: none;
  background-color: black;
  border-radius: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 850px) {

  .cursor-dot,
  .cursor-dot-outline {
    cursor: none; /* Hide the cursor */
  }
  .container-1 .button-home{
    width: 80%;
    flex-direction: column;
    justify-content: center;
    height: 50px;
  }

  .home-container {
  width:100% ;
    flex-flow: column;
padding-top: 40px;

padding-left: 10px;
padding-right: 20px;

  }
  .home-container .item.item-1 {
    display: flex;
flex-flow: column wrap;
margin-bottom: 20px;
max-height: 100%;
width: 100%;
  
  }
   .container-1 {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 20px;

  
}

  .container-1 .text {
   
  width: 100%;
   
  }
  .welcome-header1{
  display: flex;
  
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: 100%;
gap: 20px;
}

  .hello{
font-size: 3rem;
color: var(--2, #050505);
font-family: Dela Gothic One;
font-style: normal;
font-weight: 400;
line-height: 120.037%; /* 33.61px */
text-transform: uppercase;
  }
  .im{
  font-size: 2.5rem;
  color: var(--2, #050505);
font-family: Dela Gothic One;
font-style: normal;
font-weight: 400;
line-height: 105.037%; /* 45.166px */
text-transform: uppercase;
  
  
  }

  .harut{
    font-size: 2.5rem;
    color: var(--1, #38CDA1);
    font-family: Dela Gothic One;

    font-style: normal;
    font-weight: 400;
    line-height: 105.037%;
    text-transform: uppercase;
  }

  .full{
    font-size: 2rem;
    color: var(--2, #050505);
font-family: Dela Gothic One;

font-style: normal;
font-weight: 400;
line-height: 123.537%; /* 33.355px */
text-transform: uppercase;
  }

  .web{
    font-size: 1.5rem;
    color: var(--2, #050505);
    font-family: Dela Gothic One;
  
    font-style: normal;
    font-weight: 400;
    line-height: 123.537%; /* 33.355px */
    text-transform: uppercase;
  }

  .profile-picture{
    padding: 0;
  }

  .home-container .item.item-1 .container-1 .button {
  height: 100%;
  width: 100%;
  
  }
  .home-container .item.item-1 .container-1 .button .contact {
  display: flex;
align-items: center;
align-self: center;   
  }


  .home-container .item.item-1 .picture-container{
    width: 100%;
   
    padding-right: 0;
  }


.home-container .item.item-2 {
  display: flex;
  flex-flow: column ;
  width: 100%;
  gap: 10px;
align-items: center;
height: 100%;
}




.home-container .item.item-2 .social-link-container {
 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  gap: 20px;


}
 .container-1 .button-home .contact {
  width: 100%;
  height: 100;
}

.home-container .item.item-2 .social-link-container {
  width: 100%;
  gap: 20px;
  height: 100%;
  align-self: center;
}

.social-link-container .cv-item{
  display: flex;
height: 100%;
  justify-content: center;
  padding: 18px;

}
.cv .download-button{

  height: 100%;
  width: 100%;
 
}
.social-link-container .mail{
  display: flex;
  width: 100%;
height: 100%;
  justify-content: center;
}
.social-link-container .mail .mail-1{

  height: 100%;
  width: 100%;
  padding: 18px;
  margin-left: 10px;
}
.social-link-container .git {
  display: flex;
  height: 100%;
align-self: center;
  justify-content: center;

}
.social-link-container .git .git-1{
  height: 100%;
  width: 100%;
  padding: 10px;
}
.social-link-container .linkdin {
  display: flex;
height: 100%;
  justify-content: center;

}
.social-link-container .linkdin .linkdin-1 {
  height: 100%;
  width: 100%;
  padding: 10px;
}

}