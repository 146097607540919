* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.full-post-page{
  display: flex;
  justify-content: center;
}


.homefull-button{
  width: 200px;
  height: 50px;
  font-size: 25px;
  border-radius: 25px;
  background-color: #f5f5f5;
}
/* Full Post Container */
.full-post-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

/* Post Content */
.post-content {
  margin-bottom: 20px;
}

.post-content p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Comment Section */
.comment-section {
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.comment-section:hover {
  background-color: #f5f5f5;
}

/* Textarea for Adding Comments */
.textarea-fullpost {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button for Adding Comments */
.button-fullpost {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.button-fullpost:hover {
  background-color: #0056b3;
}

/* Styling for Comments */
.comment {
  margin-bottom: 15px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.comment p {
  margin: 0;
  line-height: 1.4;
}

.comment-info {
  margin-bottom: 5px;
}

.comment-info span {
  margin-right: 10px;
  font-size: 0.9em;
  color: #777;
}

.comment-textarea {
  margin-bottom: 15px;
}

.read-more{
  width: 100px;
  height: 50px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

/* Media Query for Responsiveness */
@media (max-width: 600px) {
  .full-post-container {
    padding: 10px;
  }
}
