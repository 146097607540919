
html,
body {
    * {
        cursor: none; 
    }


height: 100%;
width: 100%;
  background-color: #F1F1F1;
   
}

@media screen and (min-width: 728px) {
    .cursor-dot,
    .cursor-dot-outline {
      cursor: none; /* Hide the cursor */
    }
  }


  .app{
    width: 100%;
    height: 100%;
  }

  .main-content{
    width: 100%;
    height: 100%;
  }