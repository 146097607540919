.services-section {
  background-color: #f9f9f9;
  text-align: center;
  padding: 40px 20px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  gap: 20px; /* Add some space between the cards */
}

.service-card {
  background-color: #ffffff;
  border: 1px solid #46cea2;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.service-card:hover {
  animation: swing 0.5s ease-in-out;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.service-card .icon {
  font-size: 40px;
  margin-bottom: 20px;
}

.service-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 16px;
  color: #666;
}

.service-card ul {
  padding-left: 20px;
  text-align: left;
}

.service-card li {
  font-size: 14px;
  color: #333;
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }
}
