/* General Footer Styling */
.footer {
  background-color: #2c2c2c;
  color: #ffffff;
  padding: 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Top Section */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.footer-logo {
  max-width: 150px;
}

.copyright-text {
  font-size: 0.9rem;
  text-align: right;
  color: #ccc;
}

/* Footer Content */
.footer-content {
  text-align: left;
}

.location-info,
.contact-info {
  margin: 10px 0;
}

.location-icon,
.contact-icon {
  margin-right: 8px;
  color: #28a745;
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #28a745;
}

.contact-item {
  margin: 5px 0;
}
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
  }

  .copyright-text {
    text-align: center;
    margin-top: 10px;
  }
}
