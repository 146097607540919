


.button-blog{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;


 }
    
   
    
    .button-blog .blog-text-con-1{
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
      min-width:200px ;
      gap: 20px;
    
    }

    .button-blog .blog-text-con-1 .blog-text-1{
      margin-top: 10px;
      color: var(--2, #050505);
      font-family: Dela Gothic One;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120.037%; /* 2.62581rem */
      text-transform: uppercase;
  }

  .button-blog .blog-text-con-2{
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
 
  
  }
  .button-blog .blog-text-con-2 .blog-text-2 {
    color: var(--2, #050505);
    font-family: Montserrat;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120.037%;
    max-width: 100%; /* Set the maximum width to 100% of the parent container */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}



/* Style the container for service buttons */
.blog-svg-con {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;

}

/* Style individual service button items */
.blog-svg-item {

}

/* Style the service button image */


.blog-svg-item img {
  width: 100%; 
  height: 100%; 
 
}

 

@media (max-width:768px){
  .button-blog{

  width: 90%;
  align-self: center;
    padding: 10px;
    border-radius: 30px;
    background-color: #38CDA1;
 
   }
   .button-blog .blog-text-con-1 .blog-text-1{
    margin-top: 10px;
    color: var(--2, #050505);
    font-family: Dela Gothic One;
    font-size:7vw;
 
}

   .button-blog .blog-text-con-2 .blog-text-2 {
    color: var(--2, #050505);
    font-family: Montserrat;
    font-size: 3.5vw;

  
  }



   .blog-svg-item {
    width: 10vw;
    height: 10vw;
  }
}
