$primary: #ffffff;
$primary-ultralight: mix(white, $primary, 80%);
$primary-light: mix(white, $primary, 25%);
$primary-dark: mix(black, $primary, 25%);
$primary-ultradark: mix(black, $primary, 80%);
$black: #333333;
$white: #eeeeee;



.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
   
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 99999999;
}

.cursor-dot {
    display: flex;
    flex-direction: column;
    $size: 20px;
    width: $size;
    height: $size;
    mix-blend-mode: difference;
    background: $primary;
    z-index: 9999; /* Set a higher z-index */
}

.cursor-dot-outline {
    background-image: url(../../assets/cursor.svg);
    mix-blend-mode: difference;
    $size: 20px;
    width: $size;
    height: $size;
    z-index: 9999; 
    margin-top: 25px;

}


@media (max-width: 768px) {
    .cursor-dot,
    .cursor-dot-outline {
    display: none;
    }
}