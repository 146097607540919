/* Button Contact */
.contact {
  position: relative;
}

.contact__main-button {
  color: #000000;
  width: 200px;
  height: 70px;
  border: 2px solid;
  font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    text-transform: uppercase;
    transition: color 0.3s ease;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact__main-button:hover {
  background-color: #2EA68F;
}

.contact__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__window {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.contact__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.contact__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact__subtitle {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.contact__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact__form-label {
  display: block;
}

.contact__form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact__submit-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact__submit-button:hover {
  background-color: #218838;
}

.contact__form-error {
  color: red;
  font-size: 0.9rem;
}

.contact__success {
  text-align: center;
  color: #28a745;
}
/* Mobile Styles */
@media (max-width: 768px) {
  .contact__main-button {
    width: 100%;
    height: 60px;
    font-size: 0.9rem;
  }

  .contact__window {
    width: 95%;
    padding: 15px;
  }

  .contact__title {
    font-size: 1.2rem;
  }

  .contact__subtitle {
    font-size: 0.9rem;
  }

  .contact__form {
    gap: 10px;
  }

  .contact__form-input {
    font-size: 0.9rem;
  }

  .contact__submit-button {
    padding: 8px;
    font-size: 0.9rem;
  }
}
