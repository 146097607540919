@font-face {
    font-family: 'Dela Gothic One';
    src: url('./fonts/Dela_Gothic_One/DelaGothicOne-Regular.ttf') format('woff2'), 
         url('./fonts/Dela_Gothic_One/DelaGothicOne-Regular.ttf') format('woff'); 
    font-weight: normal;
    font-style: normal;
  }
  

  @font-face {
     font-family: 'Montserrat';
     src: url('./fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('woff2'), 
          url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('woff'); 
     font-weight: normal;
     font-style: normal;
   }


 