/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* Header styling */
.QRCode-container {


  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Project-description {

  text-align: center;

   border: 2px solid;
   border-radius: 25px;
   margin-top: 100px;
   margin-bottom: 20px;
   padding: 20px;
   box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

}


.QRCode-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}


.QRCode-size-input {
  width: 100px;
  padding: 8px;
  border-radius: 25px;
  margin-left: 10px;
}

/* File input styling */
.QRCode-file-input {
  width: 100px;
  margin-top: 10px;
  padding: 8px;
  background-color: #008CBA;
}

/* Button container styling */
.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Button styling */
.GenerateQR-button,
.DownloadQR-button,
.Home-button {
  flex: 1;
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px;
}

.GenerateQR-button {
  background-color: #4caf50;
  color: #fff;
  border-radius: 20px;

}

.DownloadQR-button {
  background-color: #008CBA;
  color: #fff;
  border-radius: 20px;
}

.Home-button {
  background-color: #ccc;
  color: #333;
}

/* QR Code container styling */
.qr-code-container {
  text-align: center;
}

.QRCode-image {
  margin-top: 10px;
}

.QRCode-text {
  margin-top: 10px;
  color: #555;
}

/* Responsive styling */
@media screen and (max-width: 600px) {
  .QRCode-container {
    padding: 10px;
  }
  
  .button-container {
    flex-direction: column;
    align-items: stretch;
  }
}
