/* Default styles for larger screens */
.myproject {
    display: flex;
    justify-content: center;
}

.myproject-container {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    height: 100%;
    padding-top: 80px;
    justify-content: center;
}

.pole-1 {
    display: flex;
    justify-content: space-between;
}

.myprojects-text {
    color: var(--2, #050505);
    font-family: Dela Gothic One;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 123.537%; /* 3.86056rem */
    text-transform: uppercase;
}

.myprojects-abouteme-container {
    display: flex;
    height: 100%;
}

.back-button {
    padding: 20px;
    text-align: left;
}

.back-button button {
    background-color: #fefefe;
    color: #000000;
    padding: 10px 15px;
    border: 2px solid;
    border-radius: 25px;
}

.back-button button:hover {
    background-color: #ffffff;
    color: #000000;
}

.pole-2 {
    width: 100%;
    height: 100%;
    display: block;
}

.projects-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: auto;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .myproject-container {
        
        max-width: 768px;
        padding-top: 40px; /* Adjusted padding for smaller screens */
    }
    .pole-1 {
     flex-direction: column;
     max-width: 768px;
    }
    .myprojects-text {
        font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    .back-button {
        padding: 10px; /* Adjusted padding for smaller screens */
    }

    .back-button button {
        padding: 5px 10px; /* Adjusted padding for smaller screens */
    }
}
