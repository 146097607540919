/* ProjectCard.css */

/* Container for the project card */
.project-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px; /* Fixed width */
  height: 400px; /* Fixed height */
}

/* Card hover effect */
.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Link styling */
.project-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Image styling */
.project-image {
  width: 100%;
  height: 250px; /* Fixed height for the image */
  object-fit: cover;
  transition: transform 0.3s;
}

/* Image hover effect */
.project-card:hover .project-image {
  transform: scale(1.05);
}

/* Details container */
.project-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  height: 150px; /* Fixed height for the details section */
}

/* Title styling */
.project-title {
  font-size: 1.2rem;
  margin: 0 0 10px;
  color: #333;
  transition: color 0.3s;
}

/* Description styling */
.project-description {
  font-size: 0.9rem;
  margin: 0;
  color: #666;
  transition: color 0.3s;
}

/* Title and description hover effect */
.project-card:hover .project-title,
.project-card:hover .project-description {
  color: #007BFF;
}

/* Media query for responsiveness */
@media (max-width: 600px) {
  .project-card {
    width: 100%; /* Ensure full width on smaller screens */
    height: auto; /* Allow height to adjust on smaller screens */
  }

  .project-image {
    height: auto; /* Allow image height to adjust on smaller screens */
  }

  .project-details {
    height: auto; /* Allow details height to adjust on smaller screens */
  }

  .project-title {
    font-size: 1rem;
  }

  .project-description {
    font-size: 0.8rem;
  }
}
